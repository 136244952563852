import request from "@/config/serve.js";
import { ZHID } from "@/common/constant/constant.js";

// 查询
export function getCategoryList(params) {
  return request("post", "/cmsCategory/list", {
    deleted: false,
    ...params,
  });
}
// 查询分类详情
export function getCategoryDetailAll(params) {
  return request("get", "/cmsCategory/item/detailAll", params);
}
//新增
export function addCategory(data) {
  return request("post", "/cmsCategory/item/add", data);
}
//修改
export function updateCategory(data) {
  return request("post", "/cmsCategory/item/put", data);
}
//删除
export function deleteCategory(data) {
  return request("get", "/cmsCategory/item/delete", data);
}
//批量删除
export function batchDeleteCategory(data) {
  return request("get", "/cmsCategory/batch/delete", data);
}
// 查询语言列表
export function getLanguageList(params) {
  return request("get", "/language/all", params);
}
// 根据父分类id查询分类
export function getCmsCategory(params) {
  return request(
    "get",
    "/cmsCategory/class",
    {
      languageId: ZHID,
      ...params,
    },
    false
  );
}
