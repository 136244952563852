<template>
  <el-form :model="formData" :rules="rules" ref="formRef" label-width="120px">
    <el-form-item label="分类等级" prop="level">
      <el-select
        clearable
        filterable
        :disabled="operationType !== 'create' || disabled"
        v-model="formData.level"
        placeholder="请选择分类等级"
        @change="handleChangeClassType"
        ><el-option
          v-for="item in classTypeList"
          :key="item.key"
          :value="item.key"
          :label="item.value"
        ></el-option
      ></el-select>
    </el-form-item>
    <el-form-item label="一级分类" prop="firstClassId" v-if="showFirstClass">
      <el-select
        filterable
        :disabled="disabled"
        v-model="formData.firstClassId"
        placeholder="请选择一级分类"
        @change="handleChangeFisrtClass"
        ><el-option
          v-for="item in firstClassList"
          :key="item.cateId"
          :value="item.cateId"
          :label="item.cateName"
        ></el-option
      ></el-select>
    </el-form-item>
    <el-form-item label="二级分类" prop="secondClassId" v-if="showSeconClass">
      <el-select
        filterable
        size="small"
        :disabled="disabled"
        v-model="formData.secondClassId"
        placeholder="请选择二级分类"
        ><el-option
          v-for="item in secondClassList"
          :key="item.cateId"
          :value="item.cateId"
          :label="item.cateName"
        ></el-option
      ></el-select>
    </el-form-item>
    <el-form-item
      v-for="(item, index) in formData.cateNameList"
      :key="index"
      label="分类名称"
      class="cate-item"
      :prop="'cateNameList.' + index"
      :rules="[
        { validator: validateCateName, trigger: 'blur' },
        { required: true, trigger: 'blur' },
      ]"
    >
      <el-input
        size="small"
        :disabled="disabled"
        placeholder="请输入分类名称"
        v-model="item.cateName"
        class="input-with-select"
      >
        <el-select
          filterable
          size="small"
          slot="prepend"
          :disabled="disabled || index === 0"
          v-model="item.languageId"
          placeholder="请选择语言"
          ><el-option
            :disabled="selectLanguageIdList.includes(item.languageId)"
            v-for="item in languageList"
            :key="item.languageId"
            :value="item.languageId"
            :label="item.languageName"
          ></el-option
        ></el-select>
      </el-input>
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="small"
        v-if="index == formData.cateNameList.length - 1"
        :disabled="disabled"
        @click="handleAddItem"
      ></el-button>
      <el-button
        v-if="index !== 0"
        type="danger"
        icon="el-icon-delete"
        size="small"
        :disabled="disabled"
        @click="handleDeleteItem(index)"
      ></el-button>
    </el-form-item>
    <el-form-item>
      <el-button
        size="small"
        type="primary"
        @click="handleSubmit()"
        :disabled="disabled"
        >立即提交</el-button
      >
      <el-button size="small" @click="handleCancel()">返回</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { MAXSIZE, ZHID } from "@/common/constant/constant.js";
import { getCmsCategory } from "./indexServe.js";
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  created() {
    this.initData(this.detailData);
  },
  data() {
    return {
      classTypeList: [
        { key: 1, value: "一级分类" },
        { key: 2, value: "二级分类" },
        { key: 3, value: "三级分类" },
      ],
      firstClassList: [],
      secondClassList: [],
      formData: {},
      rules: {
        level: [{ required: true, message: "请选择分类等级" }],
        language: [{ required: true, message: "请选择语言" }],
        firstClassId: [{ required: true, message: "请选择一级分类" }],
        secondClassId: [{ required: true, message: "请选择二级分类" }],
      },
    };
  },
  computed: {
    disabled() {
      return this.operationType === "view";
    },
    showFirstClass() {
      return this.formData.level !== 1;
    },
    showSeconClass() {
      return this.formData.level === 3;
    },
    selectLanguageIdList() {
      return this.formData.cateNameList.map((i) => i.languageId);
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const data = this.formSumbitData();
          this.$emit("submit", data);
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleAddItem() {
      this.formData.cateNameList.push({ cateName: "", languageId: "" });
    },
    handleDeleteItem(index) {
      this.formData.cateNameList.splice(index, 1);
    },
    handleChangeClassType() {
      if (this.showFirstClass) {
        // 显示一级分类时查询一级分类的列表
        this.getCmsCategory(1, { parentCateId: -1 });
      }
    },
    handleChangeFisrtClass(val) {
      if (this.showSeconClass) {
        // 显示二级分类时且一级分类发生变化时查询二级分类的列表
        this.getCmsCategory(2, { parentCateId: val });
        this.formData.secondClassId = ""; //清空二级分类的值
      }
    },
    // type:1查询一级分类，2查询二级分类
    async getCmsCategory(type, params) {
      const res = await getCmsCategory({
        ...params,
        size: MAXSIZE,
        page: 1,
      });
      if (res && res.code == 0) {
        if (type === 1) {
          this.firstClassList = res.data || [];
        } else if (type === 2) {
          this.secondClassList = res.data || [];
        }
      }
    },
    // 其他
    initData(data) {
      const formData = {
        level: 1,
        language: 1,
        cateNameList: [{ cateName: "", languageId: ZHID }],
        firstClassId: "",
        secondClassId: "",
        ...data,
      };
      if (formData.level !== 1) {
        this.getCmsCategory(1, { parentCateId: -1 });
      }
      if (formData.level === 3) {
        this.getCmsCategory(2, { parentCateId: formData.firstClassId });
      }
      this.formData = formData;
    },
    formSumbitData() {
      const { cateId, cateNameList, level, firstClassId, secondClassId } =
        this.formData;
      // 处理语言列表数据
      const data = {
        cateId,
        level,
        cateNameList,
        parentId: level === 1 ? -1 : level === 2 ? firstClassId : secondClassId,
      };
      return data;
    },
    validateCateName(rule, value, callback) {
      if (value.languageId === ZHID && value.cateName === "") {
        callback(new Error("请输入分类名称"));
      } else if (value.languageId === "" || value.cateName === "") {
        callback(new Error("请选择语言并输入分类名称"));
      } else {
        callback();
      }
    },
  },
  watch: {
    detailData(data) {
      this.initData(data);
      if (this.operationType === "create") {
        this.$refs.formRef.resetFields();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.input-with-select {
  vertical-align: middle;
  margin-right: 10px;
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 100px;
  }
}
.el-input {
  width: 500px;
}
.cate-item {
  /deep/.el-form-item__error {
    padding-left: 140px;
  }
}
</style>
