<template>
  <div class="list-area">
    <!-- <el-row>
      <el-button
        size="small"
        type="primary"
        v-has="'add'"
        icon="el-icon-plus"
        @click="handleOperate('create')"
        >添加</el-button
      >
    </el-row> -->
    <el-row :gutter="6">
      <el-col :span="4">
        <el-select
          clearable
          filterable
          v-model="searchData.firstClassId"
          placeholder="请选择一级分类"
          @change="handleChangeFisrtClass"
          ><el-option
            v-for="item in firstClassList"
            :key="item.cateId"
            :value="item.cateId"
            :label="item.cateName"
          ></el-option
        ></el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          clearable
          filterable
          v-model="searchData.secondClassId"
          placeholder="请选择二级分类"
          @change="handleChangeSecondClass"
          ><el-option
            v-for="item in secondClassList"
            :key="item.cateId"
            :value="item.cateId"
            :label="item.cateName"
          ></el-option
        ></el-select>
      </el-col>
      <!-- <el-col :span="4">
        <el-select
          clearable
          filterable
          v-model="searchData.thirdClassId"
          placeholder="请选择三级分类"
          ><el-option
            v-for="item in thirdClassList"
            :key="item.cateId"
            :value="item.cateId"
            :label="item.cateName"
          ></el-option
        ></el-select>
      </el-col> -->
      <el-col :span="4"
        ><el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column slot="prefix" type="selection" width="50" align="center"> -->
      <!-- </el-table-column> -->
      <template slot-scope="{ row }" slot="languageNums">
        <el-popover trigger="hover" v-if="row.languageDesc">
          <div style="max-width: 250px">
            {{ row.languageDesc && row.languageDesc.join("、") }}
          </div>
          <a slot="reference" class="cursor"> {{ row.languageNums }}种</a>
        </el-popover>
      </template>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            v-has="'lookinfo'"
            @click="handleOperate('view', row)"
          >
            <span class="colorGreen">查看</span>
          </el-button>
          <!-- <el-button
            type="text"
            v-has="'edit'"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          > -->
          <!-- <el-popconfirm
            v-has="'delete'"
            title="确定删除吗？"
            @confirm="handleDelete(row)"
          >
            <el-button slot="reference" class="ml10" type="text">
              <span class="colorRed">删除</span>
            </el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </PageTable>
    <!-- <div class="page-foot" v-has="'batchDelete'">
      <el-button size="small" type="primary" @click="handleBatchOperation"
        >批量删除</el-button
      >
    </div> -->
    <el-drawer
      size="50%"
      :wrapperClosable="false"
      :title="operationType | operationTypeDesc"
      :visible.sync="visible"
    >
      <Detail
        :operationType="operationType"
        :detailData="detailData"
        :languageList="languageList"
        @submit="handleSubmit"
        @cancel="visible = false"
      />
    </el-drawer>
  </div>
</template>
<script>
import { MAXSIZE } from "@/common/constant/constant.js";
import { getCmsCategory } from "./indexServe.js";
import PageTable from "@/components/PageTable.vue";
import Detail from "./Detail.vue";
import {
  getCategoryList,
  getCategoryDetailAll,
  deleteCategory,
  batchDeleteCategory,
  addCategory,
  updateCategory,
  getLanguageList,
} from "./indexServe";
export default {
  name: "categoryManagement",
  components: { PageTable, Detail },
  mounted() {
    this.init();
  },
  data() {
    return {
      languageList: [],
      firstClassList: [],
      secondClassList: [],
      thirdClassList: [],
      searchData: {
        firstClassId: "",
        secondClassId: "",
        thirdClassId: "",
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "cateId",
          label: "ID",
          width: 100,
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "firstClass",
          label: "一级分类",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "secondClass",
          label: "二级分类",
          align: "center",
          "show-overflow-tooltip": true,
        },
        // {
        //   key: "thirdClass",
        //   label: "三级分类",
        //   align: "center",
        //   "show-overflow-tooltip": true,
        // },
        {
          slot: "languageNums",
          label: "语言",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "createTime",
          label: "创建时间",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          key: "modifyTime",
          label: "更改时间",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      selectionIdList: [], //所有已选的id列表
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      this.operationType = operationType;
      this.visible = true;
      if (operationType === "create") {
        this.detailData = {};
      } else {
        this.getCategoryDetailAll(row);
      }
    },
    handleDelete(row) {
      deleteCategory({ cateId: row.cateId }).then((res) => {
        if (res && res.code == 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success(res.data);
        }
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmit(formData) {
      const fcn = this.operationType === "edit" ? updateCategory : addCategory; // 编辑和新建分别调用不同接口
      fcn(formData).then((res) => {
        if (res && res.code == 0) {
          this.visible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success(res.data);
        }
      });
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.cateId);
    },
    handleBatchOperation() {
      if (this.selectionIdList.length == 0) {
        this.$message.error("请勾选需要操作的数据");
        return;
      }
      batchDeleteCategory({ cateIdList: this.selectionIdList.join(",") }).then(
        (res) => {
          if (res && res.code == 0) {
            this.getTableList({
              page: this.tableData.page,
              size: this.tableData.size,
            }); // 删除成功后刷新列表
            this.$message.success(res.data);
          }
        }
      );
    },
    handleChangeFisrtClass(val) {
      this.searchData.secondClassId = ""; //清空二级分类的值
      this.searchData.thirdClassId = ""; //清空三级级分类的值
      if (!val) {
        this.secondClassList = [];
        this.thirdClassList = [];
        return;
      }
      this.getCmsCategory(2, { parentCateId: val });
    },
    handleChangeSecondClass(val) {
      this.searchData.thirdClassId = ""; //清空三级级分类的值
      if (!val) {
        this.thirdClassList = [];
        return;
      }
      this.getCmsCategory(3, { parentCateId: val });
    },

    // 后端请求
    // 获取列表
    async getTableList(params, isDownload) {
      const { secondClassId, firstClassId, thirdClassId } = this.searchData;
      const parentId = thirdClassId || secondClassId || firstClassId; //将当前id填入parentCateId
      const res = await getCategoryList({
        page: 1,
        size: 10,
        parentId,
        ...params,
      });
      if (res && res.code === 0) {
        const list = res.data.list.map((i) => {
          const data = i;
          if (data.level == 1) {
            data.firstClassId = data.cateId;
            data.firstClass = data.cateName;
          } else if (data.level == 2) {
            if (data.firstCategory) {
              data.firstClassId = data.firstCategory.cateId;
              data.firstClass = data.firstCategory.cateName;
            }
            data.secondClassId = data.cateId;
            data.secondClass = data.cateName;
          } else if (data.level == 3) {
            if (data.firstCategory) {
              data.firstClassId = data.firstCategory.cateId;
              data.firstClass = data.firstCategory.cateName;
            }
            if (data.secondCategory) {
              data.secondClassId = data.secondCategory.cateId;
              data.secondClass = data.secondCategory.cateName;
            }
            data.thirdClassId = data.cateId;
            data.thirdClass = data.cateName;
          }
          return data;
        });
        res.data.list = list;
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    // 获取分类详情
    async getCategoryDetailAll(row) {
      const res = await getCategoryDetailAll({ cateId: row.cateId });
      if (res && res.code === 0) {
        this.detailData = { ...row, cateNameList: res.data };
      }
    },
    // 获取语言列表
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    // type:1查询一级分类，2查询二级分类，3查询三级级分类
    async getCmsCategory(type, params) {
      const res = await getCmsCategory({
        ...params,
        size: MAXSIZE,
        page: 1,
      });
      if (res && res.code == 0) {
        if (type === 1) {
          this.firstClassList = res.data || [];
        } else if (type === 2) {
          this.secondClassList = res.data || [];
        } else if (type === 3) {
          this.thirdClassList = res.data || [];
        }
      }
    },
    // 其他
    init() {
      this.getTableList();
      this.getLanguageList();
      this.getCmsCategory(1, { parentCateId: -1 });
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
